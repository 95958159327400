import { handleErrorWithSentry } from '@sentry/sveltekit';
import { init } from '@sentry/sveltekit';
import { PUBLIC_SENTRY_ENV } from '$env/static/public';
import { version } from '$lib/../../../../version.json';
import { dsn } from '$lib/constants/sentryConstants';

init({
    dsn,
    environment: PUBLIC_SENTRY_ENV,
    sampleRate: 1.0,
    release: 'v' + version,

    ignoreErrors: [
        // Email link Microsoft Outlook crawler compatibility error
        // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:'
    ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
